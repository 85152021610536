<template>
  <div class="questionbrowsing study-active-introduce">
    <ActivityLinkInfo
      :activityInfoData="activityInfoData"
      :infoData="resourceData"
    ></ActivityLinkInfo>
    <!-- <div class="hengxian"></div> -->
    <div class="main_content">
      <div class="title-box">问卷</div>
      <!-- <div class="five cursor" v-if="textActive == true" @click="showText">
        <span>查看全部</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="five cursor" v-else-if="textActive == false" @click="showText">
        <span>收起</span>
        <i class="el-icon-arrow-up"></i>
      </div> -->

      <template v-if="subjectShow && resourceData.implement_type == 1">
        <Subject
          :data="resourceData"
          appName="问卷"
          :is_from="+is_from"
          @onSubmit="onSubmit"
          v-if="
            resourceData.status != 1 ||
            resourceData.user_id == userId ||
            resourceData.teaching_group_user_id == userId
          "
        ></Subject>
        <p class="noData" v-else>该活动暂未开始</p>
      </template>

      <!-- 线下区域 -->
      <UnderLineDetail
        :is_from="+is_from"
        :resourceData="resourceData"
        v-else-if="resourceData.implement_type == 2"
        @getResourceData="getResourceData"
        :activityId="+activityId"
        :linkId="+id"
      />
    </div>
    <!-- 结束文案对话框 -->
    <el-dialog :visible.sync="writeVisible" width="25%" :show-close="false">
      <div class="copy_write">
        <div class="write_one">
          <span>{{ copywriting }}</span>
        </div>
        <div class="write_two">
          <el-button type="primary" @click="closecopy">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Subject from "../components/subject/subject.vue";
import UnderLineDetail from "../components/underlineDetail/index.vue";
import * as researchApi from "@/api/researchApi.js";
import { queryString } from "@/utils/utils.js";
import ActivityLinkInfo from "../components/activityLinkInfo.vue";
export default {
  components: {
    Subject,
    UnderLineDetail,
    ActivityLinkInfo,
  },
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 活动Id
      activityId: "",
      // 环节id
      id: "",
      // 资源浏览数据
      resourceData: {},
      // 当前登录的用户名
      username: "",
      // 当前登录的用户id
      userId: "",

      //线下表单
      xianxiaform: {
        name: "",
        accessoryId: "",
        url: "",
      },
      // 文本是否隐藏
      textActive: true,
      //分割的问卷数据
      divisionData: [],
      //展现的分割的问卷的数据
      showdivisondata: [],
      // 文案数据
      copywriting: "",
      // 控制文案数据显示与否
      writeVisible: false,
      subjectShow: false, //是否显示题目组件
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化函数
    init() {
      this.activityId = this.$route.query.activityId;
      this.id = this.$route.query.id;
      this.is_from = this.$route.query.is_from || 1;
      this.username = window.localStorage.getItem("user_name");
      this.userId = window.localStorage.getItem("id");
      this.getResourceData();
    },
    //初始化获取问卷数据
    getResourceData() {
      if (this.is_from == 1) {
        this.$axios
          .get(`activity/${this.activityId}/activityLink/${this.id}`)
          .then((res) => {
            var resdata = res.data;
            this.resourceData = resdata.data;
            this._questionnaire();
          });
      } else {
        this.$axios
          .get(`TeachingStudioactivity/${this.activityId}/activityLink/${this.id}`)
          .then((res) => {
            this.resourceData = res.data.data;
            this._questionnaire();
            // this.resourceData.status = "2"
          });
      }
    },
    // 问卷结束文案接口
    _questionnaire() {
      this.$axios.get("default/questionnaire").then((res) => {
        this.copywriting = res.data.data.closings_txt;
        Object.assign(this.resourceData, { closingstxt: this.copywriting });
        this.subjectShow = true;
      });
    },
    // 分割数组函数
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    // 处理页数改变函数
    handlepage(val) {
      this.showdivisondata = this.divisionData[val - 1];
    },
    // 文本显示与隐藏函数
    showText() {
      if (this.textActive == true) {
        this.textActive = false;
      } else if (this.textActive == false) {
        this.textActive = true;
      }
    },
    // 点击关闭文案对话框进行的函数
    closecopy() {
      this.writeVisible = false;
    },
    //问卷提交回调
    onSubmit() {
      this.getResourceData();
    },
  },
};
</script>

<style scoped lang="less">
.questionbrowsing {
  .hengxian {
    width: 100%;
    height: 1px;
    background: #ececec;
  }
  .main_content {
    .option_box_div {
      margin-left: auto;
      font-size: 16px;
      color: #333333;
      /deep/.el-input__inner {
        background-color: #f4fbff;
      }
    }
    .five {
      color: #3489fe;
      margin-bottom: 36px;
      span {
        margin-right: 11px;
      }
    }
    .six {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 28px;
    }
    .seven {
      .test_block {
        margin-bottom: 40px;
        .block_one {
          margin-bottom: 30px;
          p {
            font-weight: 700;
            color: #1a1a1a;
            line-height: 34px;
            margin-right: 20px;
          }
        }
        .block_two {
          // margin-left: 20px;
        }
      }
    }
    .eight {
      display: flex;
      justify-content: center;
      .save {
        width: 148px;
        height: 42px;
        background: #ff8201;
        border-radius: 6px;
        font-weight: 700;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .page_style {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
}
.copy_write {
  .write_one {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .write_two {
    display: flex;
    flex-direction: row-reverse;
  }
}
.option {
  .el-radio,
  .el-radio__input {
    white-space: normal;
    line-height: 20px;
  }
  .space {
    margin-bottom: 28px;
  }
}
.new_option {
  width: 955px;
  margin-bottom: 28px;
  .el-checkbox,
  .el-checkbox__input {
    white-space: normal;
  }
  /deep/ .el-checkbox__label {
    display: inline;
    line-height: 30px;
  }
  //   /deep/ .el-checkbox__input {
  // 	display: inline-grid;
  //     white-space: pre-line;
  //     word-wrap: break-word;
  //     overflow: hidden;
  //     line-height: 20px;
  //  }
}
.multiple {
  .el-checkbox-group {
    font-size: 14px;
  }
}
.noData {
  width: 100%;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 15px;
}
</style>
